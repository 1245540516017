<template>
  <div class="ActiveTab MonitoringTab">
    <LocatieInformatieHeader :chargingpoint="chargingpoint">
      <h4>{{ $t('components.sidebar.Tabs.MonitoringTab.title') }}</h4>

      <b-form-select
        v-if="isRealized && hasConnectionPoints"
        v-model="selectedPointToShow"
        class="mt-1"
        :options="connectionPointOptions"
      />
    </LocatieInformatieHeader>

    <div class="ActiveTab__Content">
      <p
        v-if="loading"
        class="text-muted"
      >
        {{ $t('loading') }}
      </p>
      <template v-else>
        <template v-if="isRealized">
          <Feedback
            v-if="demo"
            class="mb-3"
            :dismissible="false"
            :feedback="{ variant: 'warning' }"
          >
            <strong>{{ $t('components.sidebar.ParticipationComments.demoMsg') }}</strong> <br>
            <span>{{ $t('components.sidebar.ParticipationComments.demoMsg') }} <MailtoSupport /></span>
          </Feedback>

          <div
            v-if="hasConnectionPoints"
            class="LocatieInformatie__Content"
          >
            <ChartOccupancyRate
              :data="selectedPointMonitoringData"
              :options="options.occupancy"
              :title="$t(options.occupancy.title)"
            />
            <ChartChargedVolume
              :data="selectedPointMonitoringData"
              :options="options.chargedVolume"
              :title="$t(options.chargedVolume.title)"
            />
            <ChartSessionCount
              :data="selectedPointMonitoringData"
              :options="options.session"
              :title="$t(options.session.title)"
            />
            <ChartUniqueUsers
              :data="selectedPointMonitoringData"
              :title="$t(options.uniqueUsers.title)"
            />
          </div>
          <p v-else>
            {{ $t('components.sidebar.Tabs.MonitoringTab.noData') }}
          </p>
        </template>
        <p v-else>
          {{ $t('components.sidebar.Tabs.MonitoringTab.notAvailable') }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Feedback from '@/components/form/Feedback'
import LocatieInformatieHeader from '@/components/map/sidebar/LocatieInformatieHeader'
import ChartOccupancyRate from '@/components/map/sidebar/Charts/ChartOccupancyRate'
import ChartChargedVolume from '@/components/map/sidebar/Charts/ChartChargedVolume'
import ChartSessionCount from '@/components/map/sidebar/Charts/ChartSessionCount'
import ChartUniqueUsers from '@/components/map/sidebar/Charts/ChartUniqueUsers'
import monitoringDemoData from '@/data/monitoring-demo-data.json'
import MailtoSupport from '@/components/common/MailtoSupport'
import { CHARGINGPOINT_TYPE } from '@/../shared/valueholders/chargingpoint-statuses'
import { DEFAULT_THRESHOLD } from '@/../shared/valueholders/monitoring'
import fetch from 'node-fetch'

const LAADPAAL_SELECTED = 'laadpaal_is_selected'


export default {
  components: {
    ChartOccupancyRate,
    ChartChargedVolume,
    ChartSessionCount,
    ChartUniqueUsers,
    LocatieInformatieHeader,
    Feedback,
    MailtoSupport,
  },
  data() {
    return {
      demo: false,
      data: {},
      selectedPointToShow: LAADPAAL_SELECTED,
      loading: false,
      monitoringData: undefined,
    }
  },
  computed: {
    ...mapGetters('config', [
      'isMonitoringEnabled',
      'thresholds',
    ]),
    ...mapGetters('planmode', {
      chargingpoint: 'getSelectedChargingpoint',
    }),
    ...mapGetters('monitoring', {
      locationData: 'getSelectedLocationData',
    }),
    ...mapGetters('access',  {
      activeMunicipality: 'getActiveMunicipality',
    }),
    isRealized() {
      return CHARGINGPOINT_TYPE.REALIZED.includes(this.chargingpoint.data.properties.status)
    },
    hasConnectionPoints() {
      return this.hasMonitoringData && Object.keys(this.monitoringData).length > 0
    },
    hasMonitoringData() {
      return this.monitoringData != undefined
    },
    thresholdOccupancy() {
      let threshold = parseInt(this.thresholds.occupancy.value)
      return Number.isNaN(threshold) ? DEFAULT_THRESHOLD.OCCUPANCY : threshold
    },
    thresholdChargedVolume() {
      let threshold = parseInt(this.thresholds.chargedVolume.value)
      threshold = Number.isNaN(threshold) ? DEFAULT_THRESHOLD.CHARGED_VOLUME : threshold

      if (this.selectedPointToShow === LAADPAAL_SELECTED) {
        return threshold * Object.keys(this.monitoringData || {}).length
      }

      return threshold
    },
    thresholdSession() {
      let threshold = parseInt(this.thresholds.session.value)
      threshold = Number.isNaN(threshold) ? DEFAULT_THRESHOLD.SESSION : threshold
      if (this.selectedPointToShow === LAADPAAL_SELECTED) {
        return threshold * Object.keys(this.monitoringData || {}).length
      }

      return threshold
    },
    options() {
      return {
        occupancy: {
          thresholdEnabled: this.thresholds.occupancy.enabled ?? true,
          threshold: this.thresholdOccupancy,
          title: this.selectedPointToShow == LAADPAAL_SELECTED ? 'components.sidebar.Tabs.MonitoringTab.avgOccupancy' : 'components.sidebar.Tabs.MonitoringTab.occupancy',
        },
        chargedVolume: {
          thresholdEnabled: this.thresholds.chargedVolume.enabled ?? true,
          threshold: this.thresholdChargedVolume,
          title: this.selectedPointToShow == LAADPAAL_SELECTED ? 'components.sidebar.Tabs.MonitoringTab.avgVolume' : 'components.sidebar.Tabs.MonitoringTab.volume',
        },
        session: {
          thresholdEnabled: this.thresholds.session.enabled ?? true,
          threshold: this.thresholdSession,
          title: this.selectedPointToShow == LAADPAAL_SELECTED ? 'components.sidebar.Tabs.MonitoringTab.avgSession' : 'components.sidebar.Tabs.MonitoringTab.sessions',
        },
        uniqueUsers: {
          title: this.selectedPointToShow == LAADPAAL_SELECTED ? 'components.sidebar.Tabs.MonitoringTab.avgUniqueUsers' : 'components.sidebar.Tabs.MonitoringTab.uniqueUsers',
        },
      }
    },
    connectionPointOptions() {
      const inflectedLabel = Object.keys(this.monitoringData).length > 1 ? 'laadpunten' : 'laadpunt'

      const options = Object.keys(this.monitoringData)
        .map(entry => (
          {
            value: entry,
            text: entry,
          }
        ))
        .sort((a, b) => a.text.localeCompare(b.text))

      options.unshift({ value: LAADPAAL_SELECTED, text: `Locatie (${Object.keys(this.monitoringData).length} ${inflectedLabel})` })

      return options
    },
    averageData() {
      const monitoringData = this.monitoringData ? this.monitoringData : undefined

      if (!monitoringData) return undefined

      const flatMapMonitoringValues = Object.values(monitoringData).flatMap( datapoint => datapoint)
      const totals = flatMapMonitoringValues.reduce((averageData, datapoint) => {
        const { source, month, metrics } = datapoint

        if (!averageData[month]) {
          averageData[month] = {
            label: new Date(month).toLocaleString('default', { month: 'short' }),
            session_count: 0,
            occupancy_rate: 0,
            unique_users: 0,
            total_energy: 0,
            source,
          }
        }
        Object.entries(metrics).forEach(([key, value]) => {
          if (!averageData[month][key]) {
              averageData[month][key] = 0
          }

          averageData[month][key] += value
        })

        return averageData
      }, {})
      const numOfChargingPoints = Object.keys(monitoringData).length

      Object.entries(totals).forEach(([month, metrics]) => {
        Object.entries(metrics).forEach(([key, value]) => {
          if (typeof value == 'number'){
            totals[month][key] = value / numOfChargingPoints
          }
        })
      })

      return totals
    },
    selectedPointMonitoringData(){
      const monitoringData = this.monitoringData
      const chargingPointIds = Object.keys(monitoringData)

      if (this.selectedPointToShow == LAADPAAL_SELECTED){
        return this.averageData
      }

      if (!chargingPointIds.includes(this.selectedPointToShow)) return {}

      const chargingPointData = monitoringData[this.selectedPointToShow]
      const newData = chargingPointData.reduce((data, datapoint) => {
        const { source, month, metrics } = datapoint
        if (!data[month]) {
          data[month] = {
            label: new Date(month).toLocaleString('default', { month: 'short' }),
            session_count: metrics?.session_count || 0,
            occupancy_rate: metrics?.occupancy_rate || 0,
            unique_users: metrics?.unique_users || 0,
            total_energy: metrics?.total_energy || 0,
            source: source || null
          }
        }
        return data
      }, {})

      return newData
    },
  },
  watch: {
    chargingpoint() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  destroyed() {
    this.deselectLocation()
  },
  methods: {
    ...mapActions('monitoring', [
      'fetchConnectionPointsByUuid',
    ]),
    ...mapMutations('monitoring', [
      'upsertLocationData',
    ]),
    ...mapActions('monitoring', [
      'selectLocation',
      'deselectLocation',
    ]),
    async init() {
      if (!this.isRealized) {
        return
      }

      if (this.isMonitoringEnabled === false) {
        this.initWithDemoData()
        return
      }

      await this.fetchLastMonthsUsageReport()
    },
    async initWithDemoData() {
      this.demo = true
      this.monitoringData = monitoringDemoData.data
    },
    async fetchLastMonthsUsageReport() {
      try {
        const code = this.chargingpoint.data.code
        const uuid = this.chargingpoint.data.uuid
        const numberOfMonths = 3

        this.loading = true

        const token = await this.$auth.getTokenSilently()
        const res = await fetch(
          `/api/reports-last-months-by-uuid/${code}/${uuid}?months=${numberOfMonths}`,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          },
          )
          const { data } = await res.json()
          this.monitoringData = data
          this.loading = false
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error)
          this.loading = false
      }
  },
  },
}
</script>

<style lang="scss">
  .MonitoringTab {
    &.ActiveTab {
      overflow-x: hidden;
    }

    &__SelectDropdown {
      position: absolute;
      right: 60px;
      top: 8px;
    }
  }

  .Chart {
    &__Header {
      display: flex;
      font-size: 1.15rem;

      &__Icon {
        width: 25px;
        height: 25px;
        display: flex;
        margin-right: 3px;
      }
    }
  }

  .ggl-tooltip {
    white-space: nowrap;
    padding: 5px;
  }

  // fix flickering tooltip: https://github.com/google/google-visualization-issues/issues/2162
  div.google-visualization-tooltip { pointer-events: none }
  svg > g > g:last-child { pointer-events: none }
  svg > g:last-child > g:last-child { pointer-events: none }
</style>
